import { Table, TableBody } from "@mui/material"
import { TableTopRow } from "routes/sfg20-admin/plugins/system-tab/bundles-and-demands/table-top-row"
import { Repeat } from "lib/@components/repeat"
import { RowWithBundle } from "routes/sfg20-admin/plugins/system-tab/bundles-and-demands/row-with-bundle"

export function ClientsWithBundles({ clients, bundles }) {
    if (!clients) {
        return null
    }

    clients.map((client) => {
        client.bundleDetail =
            client.bundles && client.bundles.map((bundle) => bundles.filter((b) => b._id === bundle)[0])
    })

    return (
        <>
            {clients.length} Company records found
            <Table
                size="small"
                sx={{
                    borderCollapse: "collapse",
                    "& .MuiTableCell-root": {
                        border: "1px solid #ccc",
                        padding: "4px",
                    },
                }}
            >
                <TableTopRow />
                <TableBody>
                    <Repeat list={clients} item={<RowWithBundle type="client" />} />
                </TableBody>
            </Table>
        </>
    )
}
