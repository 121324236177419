import { SystemAdminPanels, SystemAdminTabs } from "slot-definitions"
import { Tab } from "@mui/material"
import { TabPanel } from "@mui/lab"

export function registerSystemAdminTab(name, content, predicate = () => true) {
    SystemAdminTabs.plug(<Tab if={predicate} label={name} value={name} />)
    SystemAdminPanels.plug(
        <TabPanel if={predicate} value={name}>
            {content}
        </TabPanel>
    )
}
