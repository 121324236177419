import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export const logUiEvent = api((eventName, data) =>
    mutate(
        gql`
            mutation logUiEvent($eventName: String!, $data: JSONObject) {
                logUiEvent(eventName: $eventName, data: $data)
            }
        `,
        { eventName, data },
        { returns: "logUiEvent" }
    )
)
