import { getBundleAndDemandUsage } from "routes/sfg20-admin/plugins/system-tab/bundles-and-demands/controller/bundles-and-demands.controller"
import { Box } from "@mui/material"
import { UserMetaWithBundles } from "routes/sfg20-admin/plugins/system-tab/bundles-and-demands/user-meta-with-bundles"
import { UsersWithBundles } from "routes/sfg20-admin/plugins/system-tab/bundles-and-demands/users-with-bundles"
import { ClientsWithBundles } from "routes/sfg20-admin/plugins/system-tab/bundles-and-demands/clients-with-bundles"

export function BundleAndDemandUsage() {
    const data = getBundleAndDemandUsage.useResults()
    if (!data) return null
    return (
        <>
            <Box>
                <ClientsWithBundles clients={data?.results?.clients} bundles={data?.results?.bundles} />
            </Box>
            <Box>
                <UserMetaWithBundles userMeta={data?.results?.userMeta} bundles={data?.results?.bundles} />
            </Box>
            <Box>
                <UsersWithBundles users={data?.results?.users} bundles={data?.results?.bundles} />
            </Box>
        </>
    )
}
