import { appRoute } from "routes/app/home.runtime"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { Feature, isEnabled } from "lib/@components/feature"
import { Lazy } from "lib/make-lazy"
import { registerFeature } from "lib/features"
import { hasDemand } from "lib/authorization/has-demand"
import DemandGuard from "minimals-template/components/guards/DemandGuard"

export const FEATURE_FLAG_AI_SCHEDULES = registerFeature({
    numericalId: 6,
    name: "AI Search",
    id: "ai-schedules",
    description: `Turn on the AI search feature, searches the database using AI to work out what might be useful, e.g. how to maintain a florist etc.`,
    readyForProduction: true,
})

registerConditionalPageNavigation(
    () => isEnabled("ai-schedules") && hasDemand("!sharing") && hasDemand("schedules"),
    "/app/ai",
    "AI Search",
    "eos-icons:ai",
    {
        group: "Schedules",
    }
)

appRoute.register(
    "ai",
    <Feature feature={FEATURE_FLAG_AI_SCHEDULES}>
        <DemandGuard demands={["!sharing", "schedules"]} and>
            <Lazy importer={() => import("./ai-route")} extract="AiRoute" />
        </DemandGuard>
    </Feature>
)
