import {
    Autocomplete,
    Box,
    FormControlLabel,
    RadioGroup,
    Slider,
    Switch,
    TextField,
    ToggleButtonGroup,
    Typography,
} from "@mui/material"
import { createContext, useContext } from "react"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import noop from "lib/noop"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { bind } from "lib/@components/binding/bind"
import { LockableTextField, SecureLockableTextField } from "./lockabletextfield"

export const BoundTextField = bind(<TextField fullWidth variant="outlined" />)
export const BoundLockableSecureTextField = bind(<SecureLockableTextField fullWidth variant="outlined" />)
export const BoundLockedTextField = bind(<LockableTextField fullWidth variant="outlined" />)

export function BoundHTMLTypography({ field, children, defaultValue, transformIn = (v) => v, ...props }) {
    const [value] = useBoundValue(field, defaultValue)
    return (
        <Typography {...props} component="div" dangerouslySetInnerHTML={{ __html: transformIn(value) }}>
            {children}
        </Typography>
    )
}

export const BoundAutocomplete = bind(<StandardAutocomplete variant="outlined" fullWidth options={[]} />, {
    updateValueOnBlur: false,
    extract: (_, v) => v ?? _.target.value,
})

export const LabelContext = createContext()

export function useLabelContext() {
    return useContext(LabelContext)
}

export function BoundBox({ field, children, defaultValue, transformIn = (v) => v, ...props }) {
    const [value] = useBoundValue(field, defaultValue)
    return (
        <Box {...props}>
            {children}
            <Box dangerouslySetInnerHTML={{ __html: transformIn(value) }} />
        </Box>
    )
}

export function BoundTypography({ field, children, defaultValue, transformIn = (v) => v, ...props }) {
    const [value] = useBoundValue(field, defaultValue)
    return (
        <Typography {...props}>
            {children}
            {transformIn(value)}
        </Typography>
    )
}

export function StandardAutocomplete({
    label,
    InputProps,
    textFieldProps = {},
    autoSelect = false,
    fullWidth,
    variant,
    error,
    helperText,
    noLabel,
    ...props
}) {
    return (
        <LabelContext.Provider value={noLabel ? "" : label}>
            <Autocomplete
                {...(props.multiple
                    ? { onInputChange: undefined }
                    : !props.disableClearable && { onInputChange: props.onChange })}
                autoSelect={autoSelect}
                renderInput={(p) => (
                    <TextField
                        helperText={helperText}
                        variant={variant}
                        error={error}
                        fullWidth={fullWidth}
                        label={noLabel ? "" : label}
                        {...textFieldProps}
                        {...p}
                        InputProps={{ ...p.InputProps, ...InputProps }}
                    />
                )}
                {...props}
                clearIcon={!InputProps?.readOnly ? undefined : null}
            />
        </LabelContext.Provider>
    )
}

export const BoundRadioGroup = bind(<RadioGroup />, {
    extract: (_, v) => v,
    omit: ["error", "helperText", "transformIn", "transformOut"],
})

export const BoundSlider = bind(<Slider />, {
    extract: (_, v) => v,
})

export const BoundSwitch = bind(<Switch />, {
    valueProp: "checked",
    defaultValue: false,
    updateValueOnBlur: false,
    omit: ["error", "helperText"],
    transformIn: (v) => !!v,
    extract(event) {
        return !!event.target.checked
    },
})

export function StandardSwitch({ value, onChange = () => {}, label, ...props }) {
    value = !!value
    return (
        <Box ml={1}>
            <FormControlLabel
                {...props}
                control={<Switch {...props} checked={!!value} onChange={(e) => onChange(!!e.target.checked)} />}
                label={label}
            />
        </Box>
    )
}

export const BoundStandardSwitch = bind(<StandardSwitch />, { omit: ["helperText", "error"] })
export const BoundToggleButtonGroup = bind(<ToggleButtonGroup exclusive />, {
    extract(_, v) {
        return v
    },
    omit: ["helperText", "error"],
})

export const BoundTimePicker = bind(
    <TimePicker value={Date.now()} onChange={noop} renderInput={(params) => <TextField {...params} />} />,
    {
        extract: (v) => v,
    }
)
