// eslint-disable-next-line no-undef
import { Box, Card, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material"
import { server } from "lib/app-server"
import { Bound } from "lib/@components/binding/Bound"
import { showNotification } from "lib/@components/notifications"
import { If } from "lib/@components/switch"
import { forwardRef, useState } from "react"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { bind } from "lib/@components/binding/bind"
import { ImageUploadButton } from "lib/@components/image-upload-button"
import Iconify from "minimals-template/components/Iconify"

export const BoundImageUploadInput = bind(<ImageUploadInputName variant="standard" fullWidth />)

export function ImageUploadInputName({ onChange, publicFile, ...props }) {
    const [uploading, setUploading] = useState(false)
    return (
        <TextField
            onChange={onChange}
            {...props}
            InputProps={{
                startAdornment: props.value && (
                    <InputAdornment position="start">
                        <Tooltip
                            title={
                                <Box mt={2}>
                                    <img
                                        style={{ maxWidth: 290, maxHeight: 290 }}
                                        alt="expanded preview"
                                        src={props.value}
                                    />
                                </Box>
                            }
                            arrow
                        >
                            <img alt=" " src={props.value} style={{ cursor: "default", maxWidth: 24, maxHeight: 24 }} />
                        </Tooltip>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <Box>
                            <If
                                truthy={uploading}
                                then={
                                    <IconButton size="small">
                                        <Iconify icon="mdi:hourglass-full" />
                                    </IconButton>
                                }
                                else={
                                    <ImageUploadButton
                                        Component={IconButton}
                                        size="small"
                                        onRaw={upload}
                                        publicFile={publicFile}
                                    >
                                        <Iconify icon="mdi:cloud-upload" />
                                    </ImageUploadButton>
                                }
                            />
                        </Box>
                    </InputAdornment>
                ),
            }}
        />
    )

    async function upload(file, { publicFile = true }) {
        try {
            setUploading(true)
            const body = new FormData()
            body.append("upload", file)
            const response = await server(publicFile ? "/upload?publicFile=true" : "/upload", { method: "POST", body })
            if (response.ok) {
                const result = await response.text()
                onChange(result)
            }
        } catch (e) {
            showNotification("Upload failed", { severity: "error" })
        } finally {
            setUploading(false)
        }
    }
}

export const BoundImageUploadInputPicture = bind(<ImageUploadInputPicture />, {
    extract: (v) => v,
})

export function ImageUploadInputPicture({ onChange, value, PreviewProps, publicFile, ...props }) {
    const [uploading, setUploading] = useState(false)
    return (
        <Bound PreviewProps={PreviewProps}>
            <TextField
                onChange={onChange}
                {...props}
                value={value}
                InputLabelProps={{ shrink: !!value }}
                InputProps={{
                    inputComponent: ImagePreview,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Box>
                                <If
                                    truthy={uploading}
                                    then={
                                        <IconButton size="small">
                                            <Iconify icon="mdi:hourglass-full" />
                                        </IconButton>
                                    }
                                    else={
                                        !value ? (
                                            <ImageUploadButton
                                                maxWidth={1500}
                                                maxHeight={1500}
                                                Component={IconButton}
                                                size="small"
                                                onRaw={upload}
                                                publicFile={publicFile}
                                            >
                                                <Iconify icon="mdi:cloud-upload" />
                                            </ImageUploadButton>
                                        ) : (
                                            <IconButton size="small" onClick={clear}>
                                                <Iconify icon="ic:round-clear" />
                                            </IconButton>
                                        )
                                    }
                                />
                            </Box>
                        </InputAdornment>
                    ),
                }}
            />
        </Bound>
    )

    function clear() {
        onChange("")
    }

    async function upload(file, { publicFile = true }) {
        console.log(file)
        try {
            setUploading(true)
            const body = new FormData()
            body.append("upload", file)
            const response = await server(publicFile ? "/upload?publicFile=true" : "/upload", { method: "POST", body })
            if (response.ok) {
                const result = await response.text()
                console.log(result)
                onChange(result)
            }
        } catch (e) {
            showNotification("Upload failed", { severity: "error" })
        } finally {
            setUploading(false)
        }
    }
}

const ImagePreview = forwardRef(function ImagePreview({ value }, ref) {
    const { PreviewProps = {} } = useBoundContext()
    return (
        <Box mt={2} ref={ref} width={1} minHeight={40} tabIndex={0}>
            {value && (
                <Box
                    component="img"
                    sx={{ maxWidth: 500, maxHeight: 250, borderRadius: 1, mx: "auto", mb: 2, ...PreviewProps.sx }}
                    alt="expanded preview"
                    src={value}
                />
            )}
        </Box>
    )
})

export const BoundImageUploadCard = bind(<ImageUploadCard />, {
    extract: (v) => v,
})

export function ImageUploadCard({
    children,
    setImage,
    onChange,
    maxWidth,
    maxHeight,
    sx,
    fileType = "image/jpeg",
    publicFile,
}) {
    return (
        <ImageUploadButton
            Component={Card}
            onRaw={upload}
            fileType={fileType}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            sx={{ width: "350px", height: "350px", minWidth: "250px", ...sx }}
            publicFile={publicFile}
        >
            {children}
        </ImageUploadButton>
    )

    async function upload(file, { publicFile = true }) {
        try {
            const body = new FormData()
            body.append("upload", file)
            const response = await server(publicFile ? "/upload?publicFile=true" : "/upload", { method: "POST", body })
            if (response.ok) {
                const result = await response.text()
                onChange(result)
                setImage(result)
            }
        } catch (e) {
            showNotification("Upload failed", { severity: "error" })
        }
    }
}
