import { Box, Breadcrumbs, Typography } from "@mui/material"
import { ensureArray } from "lib/ensure-array"
import Iconify from "minimals-template/components/Iconify"
import { TruncatedTypography } from "lib/@components/truncated-typography"

export function Breadcrumb({ sx, rootLabel, routes = [] }) {
    const breadcrumbs = [
        <Typography key="breadcrumb-root" noWrap variant="body2" color="text.primary">
            {rootLabel}
        </Typography>,
        ...ensureArray(routes).map((item, index) => (
            <Box key={`breadcrumb-item-${index}`} sx={{ maxWidth: "20vw" }}>
                <TruncatedTypography noWrap variant="body2" color="grey.500" data-cy="breadcrumb-route-item">
                    {item}
                </TruncatedTypography>
            </Box>
        )),
    ]

    return (
        <Breadcrumbs
            sx={{ ...sx }}
            separator={<Iconify icon="ic:baseline-circle" sx={{ height: 4, width: 4, color: "grey.500" }} />}
            aria-label="breadcrumb"
        >
            {breadcrumbs}
        </Breadcrumbs>
    )
}
