import { registerFeature } from "lib/features"
import { registerSystemAdminTab } from "routes/sfg20-admin/plugins/system-tab/register-system-admin-tab"
import { Lazy } from "lib/make-lazy"
import { isEnabled } from "lib/@components/feature"

export const FEATURE_FLAG_SESSION_MANAGER = registerFeature({
    numericalId: 202,
    name: "Session Manager",
    id: "sessionmanager-all-users",
    description: `Session Manager for admin users`,
    readyForBeta: false,
    readyForProduction: false,
})

registerSystemAdminTab(
    "Active Sessions",
    <Lazy importer={() => import("./active-sessions.js")} extract="ActiveSessions" />,
    () => isEnabled(FEATURE_FLAG_SESSION_MANAGER)
)
