import { Bound } from "lib/@components/binding/Bound"
import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import { register } from "lib/routes/register"
import { useTreeRoots } from "routes/schedule/lib/useTreeRoots"
import AuthGuard from "minimals-template/components/guards/AuthGuard"

export const printRoute = (window.PrintRoute = register("/print", <PrintLayout />))

function PrintLayout() {
    /* on or off */
    useTreeRoots()
    return (
        <AuthGuard>
            <Bound isPrint={true} isPDF={true}>
                <Box
                    sx={{
                        display: { lg: "flex" },
                        minHeight: { lg: 1 },
                    }}
                >
                    <Outlet />
                </Box>
            </Bound>
        </AuthGuard>
    )
}
