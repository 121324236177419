import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getLegacyDraftCustomSchedules = api(async function getLegacyDraftCustomSchedules(subscriptionId) {
    return query(
        gql`
            query GetLegacyDraftCustomSchedules($subscriptionId: Int!) {
                getLegacyDraftCustomSchedules(subscriptionId: $subscriptionId) {
                    customScheduleId
                    code
                    title
                    label
                }
            }
        `,
        { subscriptionId },
        { returns: "getLegacyDraftCustomSchedules" }
    )
})
