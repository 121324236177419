import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getBundleAndDemandUsage = api(() =>
    query(
        gql`
            query GetBundleAndDemandUsage {
                getBundlesAndDemandUsage
            }
        `,
        {},
        { returns: "getBundlesAndDemandUsage" }
    )
)

export function useBundles() {
    //AD: Example for stateful loading
    //eslint-disable-next-line
    const { data, error, loading } = getBundlesAndDemandUsage.useResults.status()
    if (error) {
        return []
    }
    return data
}
