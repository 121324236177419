import { LegacyClientDataMigrated, WindowReload } from "event-definitions"
import { registerTab } from "lib/@components/tabs"
import { Lazy } from "lib/make-lazy"
import { appRoute } from "routes/app/home.runtime"

registerTab({
    tab: "sfg20AdminTabs",
    id: "clientMigration",
    title: "Client Data Migration",
    demands: "admin-clientmigration",
    content: <Lazy priority={10000} importer={() => import("./client-migration")} extract="ClientMigration" />,
})

appRoute.register(
    "sfg20admin/client-migration/:id",

    <Lazy
        importer={() => import("./client-migration-detail")}
        extract={"ClientMigrationDetail"}
        demands={"admin-clientmigration"}
    />
)

LegacyClientDataMigrated.handle(() => WindowReload.raise())
