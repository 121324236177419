import { Box, ListItem, ListItemButton, Stack, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { isSpecial } from "routes/schedule/tree/schedules/isSpecial"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { Selected } from "routes/schedule/components/selected"
import {
    ScheduleItemBelow,
    ScheduleItemBeneath,
    ScheduleItemCode,
    ScheduleItemIcon,
    ScheduleItemMain,
    ScheduleItemMainExtra,
    ScheduleItemRight,
    ScheduleItemWrapper,
} from "slot-definitions"
import { Bound } from "lib/@components/binding/Bound"
import { OpenSchedule } from "event-definitions"
import { alert } from "lib/@dialogs/alert"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { ScheduleEmbed } from "lib/@components/schedule-embed"
import { TruncatedTypography } from "lib/@components/truncated-typography"
import { logUiEvent } from "routes/log/controller/log-event"

ScheduleItemCode.plug(
    <ScheduleEmbed>
        <Retired />
    </ScheduleEmbed>
)

function Retired({ schedule }) {
    return schedule?.retired ? "Retired" : null
}

export function ScheduleItem({ schedule, extra = null, last, end = null, start = null }) {
    const { onClickSchedule: open = openSchedule } = useBoundContext()

    return (
        <Bound schedule={schedule}>
            <ScheduleItemWrapper.Wrapper>
                <ListItem
                    data-cy="schedule-item"
                    className="schedule-item"
                    sx={{
                        py: 0,
                        color: schedule.notLicensed || schedule.$?.notLicensed ? "unlicensed.dark" : undefined,
                    }}
                    divider={!last}
                    component="div"
                >
                    <Stack alignItems="stretch" sx={{ width: 1 }}>
                        <ListItemBox spacing={1} width={1} justifyContent={"space-between"}>
                            <Stack sx={{ minWidth: 0 }} width={1}>
                                <ListItemBox spacing={1}>
                                    <FolderItemPart sx={{ minWidth: 32 }}>
                                        <Selected />
                                    </FolderItemPart>
                                    <ListItemButton
                                        disabled={schedule.isDisabled}
                                        sx={{ px: 0, py: 0.5, m: 0 }}
                                        onClick={() => open(schedule)}
                                    >
                                        <Stack spacing={0} sx={{ width: 1 }}>
                                            <ListItemBox>
                                                {!!start && <Box flexShrink={1}>{start}</Box>}
                                                <Box
                                                    sx={{
                                                        fontWeight: isSpecial(schedule.label) ? "bold" : undefined,
                                                        mr: 1,
                                                        lineHeight: 0,
                                                    }}
                                                >
                                                    <ScheduleItemIcon.Slot schedule={schedule}>
                                                        <Iconify
                                                            icon="mdi:file-document-outline"
                                                            sx={{ width: 32, height: 32 }}
                                                        />
                                                    </ScheduleItemIcon.Slot>
                                                </Box>
                                                <Stack data-cy="schedule-item-main" sx={{ overflow: "hidden" }}>
                                                    <ScheduleItemMain.Slot schedule={schedule}>
                                                        <Box
                                                            flex={1}
                                                            overflow={"hidden"}
                                                            sx={{
                                                                fontWeight: isSpecial(schedule.label)
                                                                    ? "bold"
                                                                    : undefined,
                                                            }}
                                                        >
                                                            <Box display="flex" alignItems="center">
                                                                <TruncatedTypography>
                                                                    {schedule.label ?? schedule.title}
                                                                </TruncatedTypography>
                                                                <ScheduleItemMainExtra.Slot schedule={schedule} />
                                                            </Box>
                                                            {extra}
                                                        </Box>
                                                    </ScheduleItemMain.Slot>
                                                </Stack>
                                            </ListItemBox>
                                            <ScheduleItemBelow.Slot schedule={schedule} />
                                        </Stack>
                                    </ListItemButton>
                                </ListItemBox>
                            </Stack>
                            <Stack data-cy="schedule-item-extra-details">
                                <ListItemBox spacing={1}>
                                    <ScheduleItemCode.Slot schedule={schedule}>
                                        <Typography
                                            variant="caption"
                                            sx={{ whiteSpace: "nowrap", minWidth: 50, textAlign: "right" }}
                                        >
                                            #{schedule.code}
                                        </Typography>
                                    </ScheduleItemCode.Slot>
                                    <ScheduleItemRight.Slot schedule={schedule} />

                                    {!!end && <Box>{end}</Box>}
                                </ListItemBox>
                            </Stack>
                        </ListItemBox>
                        <ScheduleItemBeneath.Slot schedule={schedule} />
                    </Stack>
                </ListItem>
            </ScheduleItemWrapper.Wrapper>
        </Bound>
    )

    function openSchedule() {
        if (schedule.notLicensed) {
            alert("You do not have a license for this content", "Not licensed").catch(console.error)
            const payload = { _id: schedule._id, code: schedule.code, label: schedule.label }
            logUiEvent("unlicensed-content", payload)
        } else {
            OpenSchedule(schedule.id).raiseAsync(schedule, {}, { version: schedule.version })
        }
    }
}

OpenSchedule("*").after.handleOnce((schedule, params = {}, options = {}) => {
    const output = Object.entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&")
    navigate(`/app/schedules/${schedule.id}?${output}${options.version ? `version=${options.version}` : ""}`, options)
})
