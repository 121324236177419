// routes
import { RouteBroadcast, RouterLocation } from "routes"
// theme
import ThemeProvider from "./theme"
// components
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { AppRefresh } from "event-definitions"
import { Dialogs } from "lib/@dialogs/dialogs"
import MotionLazyContainer from "minimals-template/components/animate/MotionLazyContainer"
import { ChartStyle } from "minimals-template/components/chart"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { Navigator } from "minimals-template/components/Navigator"
import NotistackProvider from "minimals-template/components/NotistackProvider"
import ScrollToTop from "minimals-template/components/ScrollToTop"
import ThemeSettings from "minimals-template/components/settings"
import { Fragment, Suspense, useEffect, useState } from "react"
import { TreeRoots } from "treeRoots"
import "./index.css"
import "./bullets.scss"
import { BackdropHolder } from "lib/@components/backdropHolder"
import { FrozenRouter } from "FrozenRouter"
import { ServerEvents } from "ServerEvents"
import { DashboardHeaderLeft, RootPlugs } from "slot-definitions"
import "lib/authorization"
import "./debug-window"
import Searchbar from "routes/@layouts/app/header/Searchbar"
import { Bound } from "lib/@components/binding/Bound"
import noop from "lib/noop"
import { hasPermission } from "library/authorization"
import { Busy } from "lib/@components/busy/busy"
import { BusyOverall } from "busy-overall"
import { useLocation } from "react-router-dom"
import { logNavigation } from "routes/log/components/log.runtime"

DashboardHeaderLeft.plug(<Searchbar if={hasPermission("schedules")} />)

const addCSSRule = (sheet, selector, rules, index = sheet.cssRules.length) => {
    sheet.insertRule(`${selector} { ${rules} }`, index)
}

export default function App() {
    const [id, setId] = useState(0)
    AppRefresh.useEvent(() => setId((id) => id + 1))

    const location = useLocation()
    useEffect(() => {
        let url = location.pathname
        if (location.search) {
            url += `${location.search}`
        }
        //console.log(`Logging url ${url}`)
        logNavigation(url)
    }, [location])

    if (window.Cypress) {
        const sheet = document.styleSheets[0] // Get the first stylesheet
        addCSSRule(sheet, "#webpack-dev-server-client-overlay", "display: none !important;")
    }
    return (
        <Bound closeNow={noop} close={(fn) => () => fn()}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MotionLazyContainer>
                    <ThemeProvider>
                        <ThemeSettings>
                            <ServerEvents>
                                <TreeRoots />
                                <RootPlugs.Slot />
                                <Suspense fallback={<LoadingScreen description="Suspense" />}>
                                    <NotistackProvider>
                                        <ChartStyle />
                                        <Fragment key={id}>
                                            <ScrollToTop />
                                            <Navigator />
                                            <RouterLocation />
                                            <RouteBroadcast />
                                            <FrozenRouter />
                                            <BackdropHolder />
                                            <Dialogs />
                                        </Fragment>
                                    </NotistackProvider>
                                </Suspense>
                                <Busy />
                                <BusyOverall />
                            </ServerEvents>
                        </ThemeSettings>
                    </ThemeProvider>
                </MotionLazyContainer>
            </LocalizationProvider>
        </Bound>
    )
}
