import { DataExportTypes } from "event-definitions"
import { isEnabled } from "lib/@components/feature"
import { registerFeature } from "lib/features"
import { FeatureFlagDescription } from "./feature-flag-description"

export const FEATURE_FLAG_DOWNLOAD_BOOKLET = registerFeature({
    numericalId: 13,
    name: "Download PDF Booklet",
    id: "download-booklet",
    description: <FeatureFlagDescription $lazy />,
    readyForProduction: true,
})

DataExportTypes.handle(({ exportTypes }) => {
    if (isEnabled(FEATURE_FLAG_DOWNLOAD_BOOKLET)) {
        exportTypes.push({
            label: "PDF",
            shareLinkProperty: "canDownloadPDF",
            description: "A PDF Booklet of data shared",
        })
    }
})
