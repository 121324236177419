import { Button } from "@mui/material"
import { useDialog } from "lib/@hooks/useDialog"
import Iconify from "minimals-template/components/Iconify"
import { ClientMigrations } from "slot-definitions"
import { ClientDataMigrationDialog } from "../../client-data-migration-dialog"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { getLegacyCustomSchedules } from "./controller/get-legacy-custom-schedules"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { importLegacyCustomSchedules } from "./controller/import-legacy-custom-schedules"

const CUSTOM_DATA_MIGRATION_SCHEDULES = "custom schedules"

ClientMigrations.plug(<LegacyCustomSchedules priority={300} />)

function LegacyCustomSchedules() {
    const { subscriptionId } = useBoundContext()
    const dialog = useDialog(
        <ClientDataMigrationDialog
            type={CUSTOM_DATA_MIGRATION_SCHEDULES}
            subscriptionId={subscriptionId}
            query={getLegacyCustomSchedules}
            warning={"Only approved schedules will be migrated."}
        />
    )

    return (
        <Button variant="outlined" size="large" onClick={handleMigrate} startIcon={<Iconify icon={"ion:documents"} />}>
            Custom Schedules (Approved)
        </Button>
    )

    async function handleMigrate() {
        const data = await dialog()
        if (data && data.length > 0) {
            try {
                const selectedIds = data.map("customScheduleId")
                await importLegacyCustomSchedules(subscriptionId, selectedIds)
                successSnackbar("Custom schedules successfully migrated")
            } catch (error) {
                errorSnackbar("Error migrating custom schedules")
            }
        }
    }
}
