import { appRoute } from "routes/app/home.runtime"
import { Bound } from "lib/@components/binding/Bound"
import Schedules from "routes/schedule/schedules"
import { useParams } from "react-router-dom"
import useSettings from "minimals-template/components/@hooks/useSettings"
import Page from "minimals-template/components/Page"
import { Box, Container, Grid, Tab } from "@mui/material"
import { UpBar } from "routes/schedule/components/up-bar"
import { moveScheduleToGroup } from "routes/schedule/lib/move-schedule-to-group"
import { ensureArray } from "lib/ensure-array"
import { ScheduleItemCode, StatsPanels, StatsTabs } from "slot-definitions"
import { FacilityOverrides } from "routes/facilities/facility-overrides"
import { AdaptItemForFacilities } from "event-definitions"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { getTreeId } from "routes/schedule/lib/getTreeId"
import { ScheduleCode } from "routes/regime/schedule-code"
import { OneCard } from "lib/@components/one-card"
import { EnforceTabList } from "lib/@components/enforceTabList"
import { setFromValueParam } from "lib/setFromEvent"
import { useEffect } from "react"
import { TabContext, TabPanel } from "@mui/lab"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FoldersAndSchedules } from "routes/regime/components/folders-and-schedules"
import { navigate } from "lib/routes/navigate"
import { useReferenceState } from "lib/@hooks/use-reference"
import { pdfRoute } from "routes/pdf/pdf.runtime"
import { NotInPdf } from "lib/@components/pdf/not-in-pdf"
import { InPdf } from "lib/@components/pdf/in-pdf"
import { useFacilities } from "./lib/use-facilities"
import { createSlot } from "lib/@components/slot/create-slot"
import { NoResults } from "lib/@components/no-results/no-results"

appRoute.register(
    "facilities/facility/:id/:group",
    <Bound>
        <FacilityOverrides $lazy>
            <FacilityAndGroup $lazy />
        </FacilityOverrides>
    </Bound>
)

pdfRoute.register(
    "facilities/facility/:id/:group",
    <Bound>
        <FacilityAndGroupPDF $lazy />
    </Bound>
)

export const FacilityGroup = createSlot("FacilityGroup")

export function findDeep(items = [], predicate = () => false) {
    items = ensureArray(items)
    for (const item of items) {
        if (predicate(item)) return item
        const childResult = findDeep(item.children, predicate)
        if (childResult) return childResult
    }
    return null
}

function FacilityAndGroup() {
    const { id, group } = useParams()
    const { themeStretch } = useSettings()
    const tree = useTreeItem(getTreeId(id))
    const facilities = useFacilities(tree)
    const parentItem = useTreeItem(id)
    StatsTabs.usePlug(<Tab value="Folders" label="Spaces, Folders & Schedules" />)
    StatsPanels.usePlug(
        <TabPanel value="Folders">
            <FoldersAndSchedules />
        </TabPanel>
    )
    ScheduleItemCode.usePlug(<ScheduleCode />)
    const [tab, setTab] = useReferenceState("detailsTab", "")
    const tabs = StatsTabs.useSlot()
    useEffect(() => {
        if (!tab && tabs.length) {
            setTab(tabs[0]?.props?.value)
        }
    }, [tabs, tab, setTab])
    if (!facilities) return <NoResults />
    const facility = facilities.find((f) => f.id === id)
    const item = findDeep(facility, (i) => i.id === group)
    if (!item) return <NoResults />
    return (
        <Schedules>
            <Bound
                select={select}
                onClickSchedule={open}
                parentItem={item ?? parentItem}
                transformScheduleItem={adaptChild}
                facility={facility}
                facilityGroup={item}
            >
                <Page title={`${facility.label} - ${item.label}`}>
                    <Container
                        maxWidth={themeStretch ? false : "lg"}
                        sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
                    >
                        <NotInPdf>
                            <UpBar id={group} />
                        </NotInPdf>
                        <Box mt={3}>
                            <TabContext value={tab ?? tabs[0]?.props?.value}>
                                <OneCard>
                                    <FacilityGroup.Slot
                                        key={group}
                                        type={item.data?.type}
                                        group={item.data}
                                        item={item}
                                    />
                                    <NotInPdf>
                                        {!!tabs.length && (
                                            <ListItemBox pr={2}>
                                                <Box flex={1} />
                                                <EnforceTabList onChange={setFromValueParam(setTab)}>
                                                    {tabs}
                                                </EnforceTabList>
                                            </ListItemBox>
                                        )}
                                    </NotInPdf>
                                </OneCard>
                                <Box pb={3} />
                                <NotInPdf>
                                    <StatsPanels.Slot />
                                </NotInPdf>
                                <InPdf>
                                    <Container maxWidth="lg">
                                        {item?.data?.description && (
                                            <Box dangerouslySetInnerHTML={{ __html: item?.data?.description }} />
                                        )}
                                    </Container>
                                    <Box className="pdf-content-loaded" />
                                </InPdf>
                            </TabContext>
                        </Box>
                    </Container>
                </Page>
            </Bound>
        </Schedules>
    )

    function adaptChild(child, item) {
        const { output } = AdaptItemForFacilities(item?.data?.type ?? "default").call({
            child,
            item,
            output: (
                <Grid item xs={12}>
                    {child}
                </Grid>
            ),
        })

        return output
    }

    function select(item) {
        return () => {
            navigate(`/app/facilities/facility/${id}/${item.id}`)
        }
    }

    function open(item) {
        navigate(
            `/app/facilities/schedule/${moveScheduleToGroup(item.id, group)}${
                item.version ? `?version=${item.version}` : ""
            }`
        )
    }
}

function FacilityAndGroupPDF() {
    const { id, group } = useParams()
    const tree = useTreeItem(getTreeId(id))
    const parentItem = useTreeItem(id)
    const facilities = useFacilities(tree)
    if (!facilities) return null
    const facility = facilities.find((f) => f.id === id)
    const item = findDeep(facility, (i) => i.id === group)

    if (!item) return null
    return (
        <Bound parentItem={item ?? parentItem} facility={facility} facilityGroup={item}>
            <Container
                maxWidth="lg"
                sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
                className="allow-print"
            >
                <OneCard>
                    <FacilityGroup.Slot key={group} type={item.data?.type} group={item.data} item={item} />
                </OneCard>
                {item?.data?.description && (
                    <Box mt={4} dangerouslySetInnerHTML={{ __html: item?.data?.description }} />
                )}
            </Container>
        </Bound>
    )
}
