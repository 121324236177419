import { registerTab } from "lib/@components/tabs"

import { makeLazy } from "lib/make-lazy"

const TimelineTab = makeLazy(() => import("./timeline-tab.js"), "TimelineTab")

registerTab({
    tab: "ScheduleRightBar",
    id: "timeline",
    title: "Timeline",
    predicate(context) {
        return context.target.metadata?.published
    },
    content: <TimelineTab $lazy priority={30} />,
})
