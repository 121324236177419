import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export const logNavigationEvent = api((location) =>
    mutate(
        gql`
            mutation logNavigationEvent($location: String!) {
                logNavigationEvent(location: $location)
            }
        `,
        { location },
        { returns: "logNavigationEvent" }
    )
)
