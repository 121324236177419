import { Box, capitalize, Tooltip } from "@mui/material"
import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"
import { useParams } from "react-router"
import { getTechnicalUpdateBulletin } from "../controller/get-technical-update-bulletin"
import { getTechnicalUpdateTitle } from "./technical-update-bulletin-board"
import { NoResultsContainer } from "lib/@components/no-results/no-results"
import { ErrorPage } from "lib/@components/error-page"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { useReferenceState } from "lib/@hooks/use-reference"
import { selectLocalStorageType } from "../technical-update-consts"
import { capitalCase } from "change-case"
import { TreeToolbar } from "slot-definitions"
import Iconify from "minimals-template/components/Iconify"
import { navigate } from "lib/routes/navigate"
import { deleteBulletin } from "../controller/editor/delete-bulletin"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { confirm } from "lib/@dialogs/confirm"
import { Secure } from "lib/authorization/secure"
import { Frag } from "lib/@components/slot/frag"
import { BasicButton } from "lib/@components/basic-button"
import { redirectTechnicalUpdates } from "../helpers/redirect-technical-updates"

export function TechnicalUpdateBulletin({ parentCrumbTitle = "Technical Updates" }) {
    const { type } = useParams("type")
    const { id } = useParams("id")
    const { data, error, loading } = getTechnicalUpdateBulletin.useResults.status(id, type)
    const localStorageType = selectLocalStorageType(type)
    const [bulletinDate, setBulletinDate] = useReferenceState(localStorageType)
    redirectTechnicalUpdates(`/app/authoring-admin/${type}/${id}`)
    if (error) return <ErrorPage />
    if (loading) return <LoadingScreen />
    if (!data) return <NoResultsContainer message="No Update Found" />

    if (!bulletinDate) {
        setBulletinDate(data?.date)
    } else if (bulletinDate < data?.date) {
        setBulletinDate(data?.date)
    }
    const parentCrumbs = [parentCrumbTitle, `${capitalCase(type)} Update Bulletins`]

    return (
        <PageBreadcrumbContainer title={getTechnicalUpdateTitle(data)} sx={{ height: 1 }} parentCrumbs={parentCrumbs}>
            <Box flex={1}>
                <Secure demand={["$authoringAdmin-bulletinEditor"]} fallback={<Frag />}>
                    <TreeToolbar.Plug>
                        <Frag>
                            <Tooltip
                                title="This bulletin is ported from legacy and cannot be edited"
                                disableHoverListener={!data.portedFromLegacy}
                                arrow
                            >
                                <Box>
                                    <BasicButton
                                        text="Edit"
                                        disabled={data.portedFromLegacy}
                                        icon={<Iconify icon="ic:baseline-edit" />}
                                        onClick={navigateToEdit}
                                    />
                                </Box>
                            </Tooltip>
                            <BasicButton
                                text="Delete"
                                sx={{ ml: 0.2 }}
                                icon={<Iconify icon="ic:baseline-delete" />}
                                onClick={handleDelete}
                            />
                        </Frag>
                    </TreeToolbar.Plug>
                </Secure>
                <Box
                    className={`force-urbanist ${data.portedFromLegacy ? "legacy-technical-update" : ""}`}
                    sx={{
                        mt: 2,
                    }}
                    dangerouslySetInnerHTML={{
                        __html: data.content,
                    }}
                />
            </Box>
        </PageBreadcrumbContainer>
    )

    async function handleDelete() {
        const isConfirmed = await confirm("Are you sure?", `Delete ${capitalize(data.type)} Update`)
        if (!isConfirmed) return
        if (await deleteBulletin(id)) {
            successSnackbar("Bulletin deleted")
            navigateUponDelete()
        } else {
            errorSnackbar("Something went wrong!")
        }
    }

    function navigateUponDelete() {
        window.history.replaceState(null, null, `/app`)
        navigate(`/app/authoring-admin`)
    }

    function navigateToEdit() {
        navigate(`/app/authoring-admin/edit-update/${type}/${id}`)
    }
}
