import { Button } from "@mui/material"
import { navigate } from "lib/routes/navigate"

export function UserLink({ user }) {
    return (
        <Button
            variant="outlined"
            onClick={() => {
                navigate(`app/sfg20-admin/users/${user._id.split(":")[0]}`)
            }}
        >
            View
        </Button>
    )
}
