import { logNavigationEvent } from "routes/log/controller/log-navigation"

export async function logNavigation(location) {
    console.log("will log navigation", location)
    try {
        await logNavigationEvent(location, {})
    } catch (e) {
        /* eslint-disable-line no-empty */
    }
}
