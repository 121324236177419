import { ScheduleItemBeneath, ScheduleItemCode } from "slot-definitions"
import { isInRegime, not, notInSelector } from "routes/facilities/lib/when-parent-item"
import { and } from "lib/logic"
import { RelatedTreeDetails } from "routes/schedule/plugins/used-schedules/related-tree-details"
import { RelatedTrees } from "routes/schedule/plugins/used-schedules/related-trees"
import { WithDemand } from "lib/authorization/secure"

ScheduleItemCode.plug(({ context }) => {
    if (notInSelector(context) && !isInRegime(context)) {
        const treesPriority = context.alternate ? 50 : 500

        return (
            <WithDemand demand="manageRegime" priority={treesPriority}>
                <RelatedTrees $lazy />
            </WithDemand>
        )
    }
    return null
})
ScheduleItemBeneath.plug(<RelatedTreeDetails $lazy if={and(not(isInRegime), notInSelector)} />)
