import { TableCell, TableRow } from "@mui/material"
import { UserLink } from "routes/sfg20-admin/plugins/system-tab/bundles-and-demands/user-link"
import { ClientLink } from "routes/sfg20-admin/plugins/system-tab/bundles-and-demands/client-link"

export function RowWithBundle({ item, type }) {
    if (!item) {
        return <>No data</>
    }
    if (!item.bundleDetail?.length && !item.demands?.length) {
        return null
    }

    return (
        <TableRow>
            <TableCell sx={{ verticalAlign: "top", width: "25%" }}>
                {type === "client" && item.name}
                {type === "user" && <>{item.UserName || JSON.stringify(item)}</>}
                {type === "userMeta" && <>{item.email || item.UserName}</>}
                {(type === "user" || type === "userMeta") && (
                    <>
                        <br />
                        Company: {item.clientDetail?.name || item.clientDetail?._id.split(":")[0]}
                    </>
                )}{" "}
            </TableCell>
            <TableCell sx={{ width: "25%" }}>
                <ul style={{ margin: 0, paddingLeft: "1em" }}>
                    {item.bundleDetail?.map((bundle, i) =>
                        bundle ? <li key={`${item._id}_${i}`}>{bundle.name}</li> : null
                    )}
                </ul>
            </TableCell>
            <TableCell sx={{ width: "40%" }}>
                <ul style={{ margin: 0, paddingLeft: "1em" }}>{item.demands?.join(", ")}</ul>
            </TableCell>
            <TableCell sx={{ width: "10%", textAlign: "center" }}>
                {type === "client" && <ClientLink client={item} />}
                {type === "userMeta" && <UserLink user={item} />}
                {type === "user" && <UserLink user={item} />}
            </TableCell>
        </TableRow>
    )
}
