import { Box, Link, Stack, Typography } from "@mui/material"
import { DATE_FORMATS } from "lib/@constants/date-format"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { useParams } from "react-router"
import { pdfRoute } from "routes/pdf/pdf.runtime"
import { getSharingLinkCoverInfo } from "routes/sharing/controller/get-sharing-link-cover-info"
import Iconify from "minimals-template/components/Iconify"

pdfRoute.register("coverPage/:id/:userName", <PDFCoverPage />)

function PDFCoverPage() {
    const { id, userName } = useParams()
    const { loading, data } = getSharingLinkCoverInfo.useResults.status(id)

    if (loading) return <LoadingScreen />

    return (
        <Box className="allow-print page-break cover-page" sx={{ position: "relative", textAlign: "center" }}>
            <CoverImage />
            <CoverPageTitle companyName={data.clientCompany} />
            <CoverPageInfo id={id} sharingLinkName={data.name} sharerName={data.clientName} shareeName={userName} />
        </Box>
    )
}

function CoverImage() {
    return (
        <Box
            component="img"
            src="/assets/pdf-cover-page.png"
            alt=""
            sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        />
    )
}

function CoverPageTitle({ companyName }) {
    return (
        <Stack spacing={0} sx={{ bottom: "72.5%", left: "11%", position: "absolute", alignItems: "flex-start" }}>
            <HeaderText text={"SFG20 Content"} />
            <HeaderText text={"Prepared By"} />
            <HeaderText
                text={companyName}
                sx={{
                    maxWidth: "450px",
                    textAlign: "left",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "4",
                    "-webkit-box-orient": "vertical",
                }}
            />
        </Stack>
    )
}

function HeaderText({ text, sx }) {
    return (
        <Typography sx={{ color: "background.paper", fontSize: "50px", lineHeight: "1.1", ...sx }}>{text}</Typography>
    )
}

function CoverPageInfo({ id, sharingLinkName, sharerName, shareeName }) {
    return (
        <Stack
            spacing={0.5}
            sx={{ top: "75%", left: "6%", position: "absolute", width: "460px", alignItems: "flex-start" }}
        >
            <TextRow
                leftText={"Sharing Link"}
                rightText={sharingLinkName}
                link={`${window.location.protocol}//${window.location.host}/app?share=${id}`}
            />
            <TextRow leftText={"Shared By"} rightText={sharerName} />
            <TextRow leftText={"Downloaded By"} rightText={shareeName} />
            <TextRow leftText={"Downloaded Date"} rightText={new Date().format(DATE_FORMATS.medium)} />
            <TextRow leftText={"Valid Until"} rightText={new Date().addDays(1).format(DATE_FORMATS.medium)} />
            <MoreInfoLink />
        </Stack>
    )
}

function TextRow({ leftText, rightText, link }) {
    const coreStyle = { fontSize: "20px", color: "background.paper", lineHeight: "1.35" }

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0} width={"100%"}>
            <Typography sx={{ ...coreStyle, fontWeight: "bold" }}>{leftText}</Typography>
            <Typography
                sx={{
                    ...coreStyle,
                    width: "275px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "right",
                }}
            >
                {link && (
                    <Link sx={{ mr: 1, color: "primary.light", fontSize: "15px" }} href={link}>
                        <Iconify icon="ci:external-link" />
                    </Link>
                )}
                {rightText}
            </Typography>
        </Stack>
    )
}

function MoreInfoLink() {
    return (
        <Typography sx={{ color: "background.paper", fontSize: "16px", fontStyle: "italic" }}>
            See{" "}
            <Link sx={{ color: "primary.light" }} href={`${window.location.protocol}//${window.location.host}/app`}>
                SFG20 Facilities IQ
            </Link>{" "}
            for more information
        </Typography>
    )
}
