import { ShareLinkActions } from "slot-definitions"
import { Feature, isEnabled } from "lib/@components/feature"
import { registerFeature } from "lib/features"
import { DownloadRegimeAsXml } from "./download-regime-as-xml"
import { DataExportTypes } from "event-definitions"
import { FeatureFlagDescription } from "./feature-flag-description"

export const FEATURE_FLAG_DOWNLOAD_XML = registerFeature({
    numericalId: 10,
    name: "Download XML",
    id: "download-xml",
    description: <FeatureFlagDescription $lazy />,
    readyForBeta: false,
    readyForProduction: true,
    internalUseOnly: false,
})

ShareLinkActions.plug(
    <Feature feature={FEATURE_FLAG_DOWNLOAD_XML} priority={60}>
        <DownloadRegimeAsXml />
    </Feature>
)

DataExportTypes.handle(({ exportTypes }) => {
    if (isEnabled(FEATURE_FLAG_DOWNLOAD_XML)) {
        exportTypes.push({
            label: "XML",
            shareLinkProperty: "canDownloadXml",
            description: "Allows a download in XML format of data shared",
        })
    }
})
