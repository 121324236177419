import { ScheduleTreeBar } from "slot-definitions"
import { registerFeature } from "lib/features"
import { DownloadSchedulePDFButton } from "./download-schedule-pdf-button"
import { hasDemand } from "lib/authorization/has-demand"
import { and } from "lib/logic"
import { isEnabled } from "lib/@components/feature"
import { SharingLinkDownloadSchedulePDF } from "./sharing-link-download-schedule-pdf"
import { DataExportTypes } from "event-definitions"
import { FeatureFlagDescription } from "./feature-flag-description"

export const FEATURE_FLAG_PRINT_SCHEDULE = registerFeature({
    numericalId: 12,
    name: "Print Schedule",
    id: "downloadSchedulePdf",
    description: <FeatureFlagDescription $lazy />,
    readyForProduction: true,
})

ScheduleTreeBar.plug(<DownloadSchedulePDFButton priority={50} if={show("$!sharing")} />)

ScheduleTreeBar.plug(<SharingLinkDownloadSchedulePDF priority={50} if={show("$sharing")} />)

function show(demand) {
    return and(
        () => isEnabled(FEATURE_FLAG_PRINT_SCHEDULE),
        () => hasDemand(demand)
    )
}

DataExportTypes.handle(({ exportTypes }) => {
    if (isEnabled(FEATURE_FLAG_PRINT_SCHEDULE)) {
        exportTypes.push({
            label: "Print",
            shareLinkProperty: "canDownloadSchedulePDF",
            description: "Allows a single document to be printed or created as PDF",
        })
    }
})
