import { ShareLinkActions } from "slot-definitions"
import { DownloadTasksAsExcelSpreadsheet } from "routes/regime/plugins/download-tasks/download-tasks-as-excel-spreadsheet"
import { Feature, isEnabled } from "lib/@components/feature"
import { FEATURE_FLAG_DOWNLOAD_TASKS } from "routes/asset-register/feature_flag_download_tasks"
import { DataExportTypes } from "event-definitions"

ShareLinkActions.plug(
    <Feature feature={FEATURE_FLAG_DOWNLOAD_TASKS} priority={50}>
        <DownloadTasksAsExcelSpreadsheet />
    </Feature>
)

DataExportTypes.handle(({ exportTypes }) => {
    if (isEnabled(FEATURE_FLAG_DOWNLOAD_TASKS)) {
        exportTypes.push({
            label: "Excel",
            shareLinkProperty: "canDownloadExcel",
            description: "Allows a download in Excel (xls) format of data shared. Cells include summaries steps",
        })
    }
})
