import { Button } from "@mui/material"
import { useDialog } from "lib/@hooks/useDialog"
import Iconify from "minimals-template/components/Iconify"
import { ClientMigrations } from "slot-definitions"
import { ClientDataMigrationDialog } from "../../client-data-migration-dialog"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { getLegacyDraftCustomSchedules } from "./controller/get-legacy-draft-custom-schedules"
import { importLegacyDraftCustomSchedules } from "./controller/import-legacy-draft-custom-schedules"

const CUSTOM_DATA_MIGRATION_DRAFT_SCHEDULES = "draft custom schedules"

ClientMigrations.plug(<LegacyDraftCustomSchedules priority={300} />)

function LegacyDraftCustomSchedules() {
    const { subscriptionId } = useBoundContext()
    const dialog = useDialog(
        <ClientDataMigrationDialog
            type={CUSTOM_DATA_MIGRATION_DRAFT_SCHEDULES}
            subscriptionId={subscriptionId}
            query={getLegacyDraftCustomSchedules}
            warning={"Only draft schedules will be migrated."}
        />
    )

    return (
        <Button
            variant="outlined"
            size="large"
            onClick={handleMigrate}
            startIcon={<Iconify icon={"ion:documents-outline"} />}
        >
            Custom Schedules (Draft)
        </Button>
    )

    async function handleMigrate() {
        const data = await dialog()
        if (data && data.length > 0) {
            try {
                const selectedIds = data.map("customScheduleId")
                await importLegacyDraftCustomSchedules(subscriptionId, selectedIds)
                successSnackbar("Draft custom schedules successfully migrated")
            } catch (error) {
                errorSnackbar("Error migrating draft custom schedules")
            }
        }
    }
}
