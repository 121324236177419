import { Table, TableBody } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { RowWithBundle } from "routes/sfg20-admin/plugins/system-tab/bundles-and-demands/row-with-bundle"

import { TableTopRow } from "routes/sfg20-admin/plugins/system-tab/bundles-and-demands/table-top-row"

export function UsersWithBundles({ users, bundles }) {
    if (!users) {
        return null
    }
    users.map((user) => {
        user.bundleDetail = user.bundles && user.bundles.map((bundle) => bundles.filter((b) => b._id === bundle)[0])
    })

    return (
        <>
            {users.length} User records found
            <Table
                size="small"
                sx={{
                    borderCollapse: "collapse",
                    "& .MuiTableCell-root": {
                        border: "1px solid #ccc",
                        padding: "4px",
                    },
                }}
            >
                <TableTopRow />
                <TableBody>
                    <Repeat list={users} item={<RowWithBundle type="user" />} />
                </TableBody>
            </Table>
        </>
    )
}
