import { TableCell, TableHead, TableRow } from "@mui/material"

export function TableTopRow() {
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={{ verticalAlign: "top", width: "25%" }}>Name</TableCell>
                <TableCell sx={{ width: "25%" }}>Bundle(s)</TableCell>
                <TableCell sx={{ width: "40%" }}>Demands(s)</TableCell>
                <TableCell sx={{ width: "10%" }}></TableCell>
            </TableRow>
        </TableHead>
    )
}
